<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <RegistrationForm />
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BImg } from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'

import RegistrationForm from './RegistrationForm.vue'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    RegistrationForm,
  },
  mixins: [togglePasswordVisibility],

  data() {
    return {
      sideImg: '',
      imgUrl: '',
    }
  },

  async created() {
    localStorage.removeItem('current_event')
    this.sideImg = await this.$generateDownloadUrl('media/login.svg')
    if (this.$store.state.appConfig.layout.skin === 'dark') {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.imgUrl = await this.$generateDownloadUrl('media/login-v2-dark.svg')
    }
    this.imgUrl = this.sideImg
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
